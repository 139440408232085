<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit" v-if="show">
          <b-form-group id="input-date" label="Date" label-for="input-date">
            <b-form-datepicker
              id="input-date-input"
              v-model="item.date"
              required
              class="mb-2"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            id="input-group-containerId"
            label="Type"
            label-for="input-containerId"
          >
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="item.containerId"
              :options="containerIds"
              required
            >
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-title"
            label="Title"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="item.title"
              type="text"
              required
              placeholder="Enter title"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="textarea-group-description"
            label="description"
            label-for="textarea-description"
          >
            <b-form-textarea
              id="'textarea-description"
              v-model="item.description"
              placeholder="Enter description"
              rows="3"
              max-rows="8"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="input-group-location"
            label="Location"
            label-for="input-location"
          >
            <b-form-input
              id="input-location"
              v-model="item.location"
              type="text"
              placeholder="Enter location"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-purchaseTicketLink"
            label="Purchase Ticket Link"
            label-for="input-purchaseTicketLink"
          >
            <b-form-input
              id="input-purchaseTicketLink"
              v-model="item.purchaseTicketLink"
              type="text"
              placeholder="Enter Purchase Ticket Link"
            ></b-form-input>
          </b-form-group>

          <div class="form-submit">
            <b-button type="submit" variant="secondary">
              <b-spinner small v-if="isLoading"></b-spinner>Submit
            </b-button>
            <input class="id-info" readonly v-model="item.id" />
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
.id-info {
  margin-left: 32px;
}
</style>

<script>
import { XmlEntities } from "html-entities";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
const entities = new XmlEntities();

export default {
  data() {
    return {
      isLoading: false,
      item: {},
      show: true,
      id: null,
      isEditMode: false,
      containerIds: [
        { value: "key_dates", text: "key_dates" },
        { value: "meet_susan", text: "meet_susan" }
      ]
    };
  },
  props: ["aboveTable", "editingId"],
  watch: {
    editingId: function(newId, oldId) {
      if (newId === oldId) return;
      this.id = newId;
      this.getItem();
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        this.isLoading = true;
        localStorage.setItem(
          "calendar-day-last-values",
          JSON.stringify({
            date: this.item.date,
            containerId: this.item.containerId
          })
        );
        const url = this.isEditMode
          ? "calendarDays?id=" + this.id
          : "calendarDays";
        ApiService.post(url, this.item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Event has been updated sucessfully!", {
                title: "Success",
                variant: "success"
              });
              // const item = data.response.item;
              // this.id = item.id;
              // this.isEditMode = true;
              // this.item = item;
              this.newItem();
              this.$emit("saved");
            } else {
              this.$bvToast.toast("Something went wrong while event ...", {
                title: "Error",
                variant: "danger"
              });
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast("Something went wrong while event ...", {
              title: "Error",
              variant: "danger"
            });
          });
      });
    },
    newItem: function() {
      const lastValue = JSON.parse(
        localStorage.getItem("calendar-day-last-values")
      );
      this.id = undefined;
      this.item = {
        date: lastValue.date,
        containerId: lastValue.containerId
      };
      this.isEditMode = false;
    },
    getItem: function() {
      if (!this.aboveTable) {
        this.id = this.$route.params.id || this.item.id || this.$route.query.id;
      }
      this.isEditMode = !!this.id;

      if (this.isEditMode) {
        return new Promise(resolve => {
          ApiService.get("calendarDays", { id: this.id })
            .then(({ data }) => {
              let item =
                data.response && data.response.items.length > 0
                  ? data.response.items[0]
                  : null;
              if (item) {
                item.content = entities.decode(item.content);
                this.item = item;
              } else {
                this.item = {};
                this.isEditMode = false;
              }
              resolve();
            })
            .catch(() => {
              this.$bvToast.toast(
                "Something went wrong while retrieving data from external server...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            });
        });
      } else {
        this.newItem();
      }
    }
  },
  mounted() {
    this.getItem();
    if (!this.aboveTable) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Calendar Days", route: "calendarDays" },
        { title: this.isEditMode ? "Edit Event" : "Add Event" }
      ]);
    }
  },
  computed: {}
};
</script>
